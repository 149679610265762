var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React, { useEffect, useState } from 'react';
import { object___pencil } from '@lsg/icons';
import { Formik, Form as FormikForm } from 'formik';
import { parsePhoneNumber } from 'libphonenumber-js';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { components } from 'react-select';
import { useTheme } from 'styled-components';
import { useAddContactDetailsMutation } from 'api';
import { Button, Input, ReusableSvg, Select, Loader } from 'components';
import { DEFAULT_COUNTRY_CODE } from 'constants/index';
import { ROUTES } from 'constants/routes';
import { LOCALES } from 'i18n/locales';
import { contactDetailsMessages, globalMessages } from 'i18n/messages';
import { SectionParagraph, DataProtectionInfoParagraph, } from 'pages/ContactDetails/ContactDetails.styles';
import { FlexContainer } from 'shared/styled';
import { ContactDetailsSchema, EmailSchema } from 'shared/validationSchemas';
import { userSelector } from 'store';
import { notificationSuccessHandler } from 'utils/notificationHandlers';
import { ContactUsInputWrapper } from 'views/ContactUsForm/ContactUsForm.styles';
import { StyledContactOption } from './ContactsSelection.styles';
const customStyles = {
    input: (provided, state) => {
        return Object.assign(Object.assign({}, provided), { color: state.selectProps.variantConfig.inputBackground === 'white' ? 'inherit' : 'white' });
    },
};
const createOption = (label) => ({
    label,
    value: label.toLowerCase().trim(),
    currentlyCreated: true,
});
const ContactCustomOption = (_a) => {
    var _b;
    var { children } = _a, props = __rest(_a, ["children"]);
    const options = props.selectProps.options;
    return (React.createElement(components.Option, Object.assign({}, props),
        React.createElement(StyledContactOption, null,
            children,
            ((_b = options.find((o) => (o === null || o === void 0 ? void 0 : o.value) === children)) === null || _b === void 0 ? void 0 : _b.currentlyCreated) && (React.createElement(ReusableSvg, { lsgIcon: true, icon: object___pencil, width: "14px", height: "14px", marginLeft: "auto", marginRight: "0px" })))));
};
export const ContactsSelection = ({ variant = 'primary', withPhoneSection, hasGreenBackground, formId, navigateAfterSubmit = false, displayDataProtectionNotification = false, onSubmit, onSelectedEmailChange, }) => {
    const theme = useTheme();
    const navigate = useNavigate();
    const { formatMessage } = useIntl();
    const { id: userId, availableEmails, availablePhone, language, selectedEmail: userSelectedEmail, selectedTelephone: userSelectedTelephone, notSupported, } = useSelector(userSelector);
    const [addContactDetails, { isLoading: isAddContactDetailsLoading }] = useAddContactDetailsMutation({
        fixedCacheKey: 'CONTACT_SELECTION_CACHE_KEY',
    });
    const [phoneOptions, setPhoneOptions] = useState([]);
    const [emailOptions, setEmailOptions] = useState([]);
    const [showEmailInputField, setShowEmailInputField] = useState(!(availableEmails === null || availableEmails === void 0 ? void 0 : availableEmails.length));
    const [showPhoneInputField, setShowPhoneInputField] = useState(!(availablePhone === null || availablePhone === void 0 ? void 0 : availablePhone.length));
    const [shouldOpenEmailMenu, setShouldOpenEmailMenu] = useState(false);
    const [shouldOpenPhoneMenu, setShouldOpenPhoneMenu] = useState(false);
    useEffect(() => {
        setEmailOptions(() => {
            const emailOptions = (availableEmails === null || availableEmails === void 0 ? void 0 : availableEmails.map((email) => ({
                value: email,
                label: email,
                keepOpen: false,
            }))) || [];
            emailOptions === null || emailOptions === void 0 ? void 0 : emailOptions.push({
                value: formatMessage(globalMessages.addEmail),
                label: formatMessage(globalMessages.addEmail),
                keepOpen: true,
            });
            return emailOptions;
        });
        setPhoneOptions(() => {
            const phoneOptions = (availablePhone === null || availablePhone === void 0 ? void 0 : availablePhone.map((phone) => ({
                value: phone,
                label: phone,
                keepOpen: false,
            }))) || [];
            phoneOptions === null || phoneOptions === void 0 ? void 0 : phoneOptions.push({
                value: formatMessage(globalMessages.addTelephone),
                label: formatMessage(globalMessages.addTelephone),
                keepOpen: true,
            });
            return phoneOptions;
        });
    }, [availablePhone, availableEmails, language]);
    const handleEmailChange = (email, setFieldValue) => {
        if ((email === null || email === void 0 ? void 0 : email.value) === formatMessage(globalMessages.addEmail)) {
            setFieldValue('email', '');
            setShowEmailInputField(true);
            return;
        }
    };
    const handlePhoneChange = (phone, setFieldValue) => {
        if ((phone === null || phone === void 0 ? void 0 : phone.value) === formatMessage(globalMessages.addTelephone)) {
            setFieldValue('phone', '');
            setShowPhoneInputField(true);
            return;
        }
    };
    const handleCreateOption = (inputValue) => __awaiter(void 0, void 0, void 0, function* () {
        if (!inputValue) {
            return;
        }
        const emailsInOptions = emailOptions.map((e) => e.value);
        if (emailsInOptions.includes(inputValue)) {
            return;
        }
        const isValid = yield EmailSchema.isValid({ email: inputValue });
        if (!isValid) {
            onSelectedEmailChange === null || onSelectedEmailChange === void 0 ? void 0 : onSelectedEmailChange(inputValue);
            return;
        }
        const newOption = createOption(inputValue);
        const newOptions = [...emailOptions];
        newOptions.splice(newOptions.length - 1, 0, newOption);
        onSelectedEmailChange === null || onSelectedEmailChange === void 0 ? void 0 : onSelectedEmailChange(newOption.value);
        setEmailOptions(newOptions);
    });
    const createLabel = (inputText) => `${formatMessage(globalMessages.addEmail)}: ${inputText}`;
    const handleBlur = (event) => __awaiter(void 0, void 0, void 0, function* () {
        var _a;
        if (event instanceof Event) {
            const inputValue = (_a = event.target) === null || _a === void 0 ? void 0 : _a.value;
            const emailsInOptions = emailOptions.map((e) => e.value);
            if (inputValue === '' || emailsInOptions.includes(inputValue)) {
                return;
            }
            onSelectedEmailChange === null || onSelectedEmailChange === void 0 ? void 0 : onSelectedEmailChange(inputValue);
            const newOption = createOption(inputValue);
            const newOptions = [...emailOptions];
            newOptions.splice(newOptions.length - 1, 0, Object.assign({}, newOption));
            onSelectedEmailChange === null || onSelectedEmailChange === void 0 ? void 0 : onSelectedEmailChange(newOption.value);
            setEmailOptions(newOptions);
        }
    });
    const navigateToNextPage = (userNotSupported, emailVerified) => {
        if (!navigateAfterSubmit) {
            return;
        }
        if (!emailVerified) {
            navigate(ROUTES.EMAIL_VERIFICATION);
            return;
        }
        if (!userNotSupported) {
            navigate(ROUTES.ACCOUNT_SELECTION);
            return;
        }
        navigate(ROUTES.TOURIST_USERS);
    };
    const handleSubmit = (values, formikBag) => __awaiter(void 0, void 0, void 0, function* () {
        var _a, _b, _c;
        const isValid = yield ContactDetailsSchema(true).isValid(values);
        if (!isValid) {
            return;
        }
        try {
            let isEmailVerified = Boolean(availableEmails === null || availableEmails === void 0 ? void 0 : availableEmails.includes(values.email));
            const userContactsChanged = values.email !== userSelectedEmail ||
                ((_a = values.phone) === null || _a === void 0 ? void 0 : _a.replace(/\s/g, '')) !== (userSelectedTelephone === null || userSelectedTelephone === void 0 ? void 0 : userSelectedTelephone.replace(/\s/g, ''));
            if (userContactsChanged) {
                isEmailVerified = yield addContactDetails({
                    userId: userId,
                    email: values.email,
                    phone: values.phone,
                }).unwrap();
                if (isEmailVerified) {
                    notificationSuccessHandler(formatMessage(globalMessages.contactDetailsUpdateSuccess));
                }
            }
            onSubmit === null || onSubmit === void 0 ? void 0 : onSubmit();
            navigateToNextPage(notSupported, isEmailVerified);
        }
        catch (err) {
            if ((_b = err === null || err === void 0 ? void 0 : err.data) === null || _b === void 0 ? void 0 : _b.localErrors) {
                try {
                    Object.keys((_c = err === null || err === void 0 ? void 0 : err.data) === null || _c === void 0 ? void 0 : _c.localErrors).forEach((field) => {
                        var _a, _b, _c, _d;
                        formikBag.setFieldError(field, (_d = (_c = (_b = (_a = err === null || err === void 0 ? void 0 : err.data) === null || _a === void 0 ? void 0 : _a.localErrors) === null || _b === void 0 ? void 0 : _b[field]) === null || _c === void 0 ? void 0 : _c[0]) === null || _d === void 0 ? void 0 : _d[language]);
                    });
                }
                catch (err) {
                    console.log(err);
                }
            }
        }
    });
    const initialFormValues = {
        email: userSelectedEmail !== null && userSelectedEmail !== void 0 ? userSelectedEmail : '',
        phone: (() => {
            try {
                const phoneNumber = parsePhoneNumber(userSelectedTelephone !== null && userSelectedTelephone !== void 0 ? userSelectedTelephone : '');
                return phoneNumber === null || phoneNumber === void 0 ? void 0 : phoneNumber.formatInternational();
            }
            catch (e) {
                return '';
            }
        })(),
    };
    return (React.createElement(Formik, { initialValues: initialFormValues, enableReinitialize: true, validateOnChange: true, validationSchema: ContactDetailsSchema(withPhoneSection), onSubmit: handleSubmit }, ({ values, errors, touched, submitCount, setFieldValue, validateField, isSubmitting }) => {
        const formIsSubmitted = submitCount > 0;
        const showEmailError = Boolean(errors.email && touched.email && formIsSubmitted);
        return (React.createElement(FormikForm, { id: formId },
            isSubmitting && React.createElement(Loader, null),
            React.createElement(FlexContainer, { direction: "column", alignItems: "initial", gap: theme.spacing(showEmailError ? 3 : 2) },
                !(availableEmails === null || availableEmails === void 0 ? void 0 : availableEmails.length) || showEmailInputField ? (React.createElement(Input.Formik, { name: "email", label: formatMessage(globalMessages.emailAddress), fadedPlaceholder: true, placeholder: formatMessage(globalMessages.emailPlaceholder), fontSize: theme.fontSizes[15], fontWeight: theme.typography.fontWeights.medium, width: "100%", hasGreenBackground: hasGreenBackground, autoFocus: true, showError: showEmailError, onChange: () => {
                        validateField('email');
                    }, onClear: () => {
                        setFieldValue('email', '');
                        setShowEmailInputField(false);
                        setShouldOpenEmailMenu(true);
                    } })) : (React.createElement(Select.Formik, { name: "email", options: emailOptions, label: formatMessage(globalMessages.emailAddress), onChange: (email) => {
                        handleEmailChange(email, setFieldValue);
                    }, value: { value: values.email, label: values.email }, onCreateOption: (v) => {
                        handleCreateOption(v);
                        setFieldValue('email', v);
                    }, formatCreateLabel: createLabel, isCreatableSelect: false, error: Boolean(errors.email && touched.email && formIsSubmitted), errorText: typeof errors.email === 'string'
                        ? errors.email
                        : formatMessage(globalMessages.invalidEmail), variantConfig: variant === 'secondary'
                        ? { menuBackground: 'white', inputBackground: 'petrol', size: 'medium' }
                        : undefined, styles: variant === 'secondary' ? customStyles : undefined, onBlur: handleBlur, components: { Option: ContactCustomOption }, defaultMenuIsOpen: shouldOpenEmailMenu })),
                withPhoneSection && (React.createElement(FlexContainer, { direction: "row", gap: theme.spacing(1), alignItems: "flex-end" },
                    React.createElement(ContactUsInputWrapper, null, !(availablePhone === null || availablePhone === void 0 ? void 0 : availablePhone.length) || showPhoneInputField ? (React.createElement(Input.Formik, { name: "phone", inputFieldHeight: "35px", label: formatMessage(globalMessages.telephone), fadedPlaceholder: true, placeholder: formatMessage(globalMessages.phonePlaceholder), fontSize: theme.fontSizes[15], fontWeight: theme.typography.fontWeights.medium, hasGreenBackground: hasGreenBackground, showError: Boolean(errors.phone && touched.phone && formIsSubmitted), replacePattern: new RegExp(/[^0-9+]/g), onBlur: () => {
                            var _a, _b;
                            try {
                                const newPhone = (_b = parsePhoneNumber((_a = String(values.phone)) !== null && _a !== void 0 ? _a : '', {
                                    defaultCountry: DEFAULT_COUNTRY_CODE,
                                })) === null || _b === void 0 ? void 0 : _b.formatInternational();
                                setFieldValue('phone', newPhone);
                            }
                            catch (e) { }
                        }, onChange: () => {
                            if (formIsSubmitted) {
                                validateField('phone');
                            }
                        }, onClear: () => {
                            setFieldValue('phone', '');
                            setShowPhoneInputField(false);
                            setShouldOpenPhoneMenu(true);
                        } })) : (React.createElement(Select.Formik, { name: "phone", options: phoneOptions, label: formatMessage(globalMessages.telephone), onChange: (phone) => {
                            handlePhoneChange(phone, setFieldValue);
                        }, value: { value: values.phone, label: values.phone }, onCreateOption: (v) => {
                            handleCreateOption(v);
                            setFieldValue('phone', v);
                        }, formatCreateLabel: createLabel, isCreatableSelect: false, error: Boolean(errors.phone && touched.phone && formIsSubmitted), errorText: typeof errors.phone === 'string'
                            ? errors.phone
                            : formatMessage(globalMessages.phoneNumberNotValid), variantConfig: variant === 'secondary'
                            ? { menuBackground: 'white', inputBackground: 'petrol', size: 'medium' }
                            : undefined, styles: variant === 'secondary' ? customStyles : undefined, onBlur: handleBlur, components: { Option: ContactCustomOption }, defaultMenuIsOpen: shouldOpenPhoneMenu })))))),
            variant === 'secondary' && (React.createElement(React.Fragment, null,
                React.createElement(SectionParagraph, { extraBottomMargin: !displayDataProtectionNotification }, formatMessage(contactDetailsMessages.provideEmail)),
                displayDataProtectionNotification && (React.createElement(DataProtectionInfoParagraph, null, formatMessage(contactDetailsMessages.dataProtectionNotification))),
                React.createElement(FlexContainer, { justifyContent: "flex-end" },
                    React.createElement(Button, { disabled: isAddContactDetailsLoading, type: "submit", variant: "primary" }, language === LOCALES.ENGLISH
                        ? formatMessage(globalMessages.continue)
                        : formatMessage(globalMessages.nextStep)))))));
    }));
};
export default ContactsSelection;
