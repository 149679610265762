import styled, { css } from 'styled-components';
import { scrollable } from 'utils/scrollable';
export const NeedHelpSection = styled.div `
	display: flex;
	flex-direction: column;
	margin: ${({ theme }) => `${theme.spacing(2)} ${theme.spacing(0)}`};
`;
export const NeedHelpHeading = styled.h2 `
	margin-bottom: ${({ theme }) => theme.spacing(2)};
	color: ${({ hasError, theme }) => (hasError ? theme.colors.red : 'inherit')};
`;
export const CheckboxWrapper = styled.div `
	display: grid;
	grid-template-columns: minmax(160px, auto) 1fr;
	align-items: flex-end;
	margin-bottom: ${({ theme }) => theme.spacing(2)};
`;
export const ContactUsInputWrapper = styled.div `
	width: 100%;

	${({ isDisabled, theme }) => isDisabled &&
    css `
			opacity: ${theme.opacity[20]};
			pointer-events: none;
		`}
`;
export const ScreenshotWrapper = styled.div `
	display: flex;
	padding-bottom: ${({ theme }) => `${theme.spacing(2)}`};
`;
export const ScreenshotImageWrapper = styled.div `
	position: relative;
	width: 50%;
	margin-right: ${({ theme }) => theme.spacing(3)};
`;
export const StyledScreenshotImage = styled.img `
	position: absolute;
	left: ${({ theme }) => theme.spacing(4)};
	top: 50%;
	transform: translate(0, -50%);
	width: ${({ theme }) => theme.spacing(12.5)};
	height: auto;
	background: white;
	cursor: pointer;
`;
export const FullScreenshotImage = styled.div `
	display: flex;
	align-items: center;
	justify-content: space-around;
	position: fixed;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	height: 100vh;
	width: 100vw;
	background: rgba(0, 34, 40, 0.35);
	z-index: 110;
`;
export const StyledBigScreenshotImage = styled.img `
	width: 80%;
	height: auto;
	background: white;
`;
export const ScreenshotButtonsWrapper = styled.div `
	display: grid;
	grid-template-rows: 35px 35px;
	row-gap: 10px;
`;
export const UserPreviouslyAskedQuestionsButton = styled.p `
	margin-bottom: ${({ theme }) => theme.spacing(1)};
	cursor: pointer;
	&:hover {
		color: ${({ theme }) => theme.colors.red};
	}
`;
export const UserPreviouslyAskedQuestions = styled.div `
	border: 2px solid ${({ theme }) => theme.colors.grey};
	height: 30vh;
	${scrollable()}
`;
export const UserPreviouslyAskedQuestionWrapper = styled.div `
	border-bottom: 2px solid ${({ theme }) => theme.colors.grey};
	padding: ${({ theme }) => theme.spacing(1)};
`;
export const UserPreviouslyAskedQuestion = styled.p ``;
export const UserQuestionCreatedDate = styled.p `
	font-size: ${({ theme }) => theme.fontSizes[12]};
	font-style: italic;
	padding-top: ${({ theme }) => theme.spacing(1)};
	text-align: end;
`;
export const UserQuestionsButtonWrapper = styled.div `
	position: absolute;
	top: -40px;
	right: 0;
`;
