import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { ROUTES } from 'constants/index';
export const ProtectedRoute = ({ children, canActivate = false, redirectTo = ROUTES.LOGIN_PORTALS, errorRedirectRoute = ROUTES.ERROR_PAGE, }) => {
    const { pathname } = useLocation();
    if (canActivate === false && redirectTo === pathname) {
        console.error(`Trying to access same ${pathname} route!`);
        return React.createElement(Navigate, { to: errorRedirectRoute });
    }
    return React.createElement(React.Fragment, null, canActivate ? children : React.createElement(Navigate, { to: redirectTo }));
};
export default ProtectedRoute;
