import { createSelector } from '@reduxjs/toolkit';
import { initialUserState, userApiEndpoints } from 'api/endpoints/userApi';
import { ONBOARDING_PRODUCTS_AVAILABILITY_MAPPER, productOptionsMapper } from 'utils/mappers';
import { transformUser } from 'utils/user';
export const getUserQueryResult = (state) => userApiEndpoints.getUser.select(state.auth.decodedAuthTokenNameId || '')(state);
export const userQuerySelector = (state) => { var _a; return (_a = getUserQueryResult(state)) === null || _a === void 0 ? void 0 : _a.data; };
export const userSelector = createSelector([userQuerySelector], (user = initialUserState) => {
    return transformUser(user);
});
export const missingPreferredIncomingAndOutgoingAccountForCurrencySelector = createSelector([userSelector], (user) => {
    var _a, _b;
    if (!user.selectedRequest || !('tradingInstructions' in user.selectedRequest)) {
        return [];
    }
    const allAccounts = ((_b = (_a = user.selectedRequest) === null || _a === void 0 ? void 0 : _a.tradingInstructions) === null || _b === void 0 ? void 0 : _b.settlementInstructions) || [];
    const initialAccountsByCurrency = {};
    const accountsByCurrency = allAccounts.reduce((result, current) => {
        if (result[current.currency]) {
            result[current.currency].push(current);
        }
        else {
            result[current.currency] = [current];
        }
        return result;
    }, initialAccountsByCurrency);
    return Object.keys(accountsByCurrency).filter((currency) => {
        if (accountsByCurrency[currency].length === 1) {
            return false;
        }
        const hasIncomingAndOutgoingAccounts = accountsByCurrency[currency].some((account) => account.preferredOutgoing) &&
            accountsByCurrency[currency].some((account) => account.preferredIncoming);
        return !hasIncomingAndOutgoingAccounts;
    });
});
export const userAvailableProductsOptionsSelector = createSelector([userSelector, (_, translator) => translator], ({ selectedRequest }, translator) => {
    if (!selectedRequest || selectedRequest.isManual) {
        return [];
    }
    return selectedRequest === null || selectedRequest === void 0 ? void 0 : selectedRequest.availableProducts.map(({ product, availability }) => productOptionsMapper(availability, translator)[product]);
});
export const userHasAllProductsEnabledForSelectedRequestSelector = createSelector([userSelector], ({ selectedRequest }) => {
    if (selectedRequest === null || selectedRequest === void 0 ? void 0 : selectedRequest.isManual) {
        return false;
    }
    return Boolean((selectedRequest === null || selectedRequest === void 0 ? void 0 : selectedRequest.availableProducts.length) &&
        (selectedRequest === null || selectedRequest === void 0 ? void 0 : selectedRequest.availableProducts.every(({ availability }) => availability === ONBOARDING_PRODUCTS_AVAILABILITY_MAPPER.ENABLED)));
});
export const userHasAllProductsEnabledForNormalRequestsSelector = createSelector([userSelector], ({ requests: normalRequests = [] }) => {
    return Boolean(normalRequests.length &&
        normalRequests.every((request) => {
            var _a;
            return (_a = request === null || request === void 0 ? void 0 : request.availableProducts) === null || _a === void 0 ? void 0 : _a.every(({ availability }) => availability === ONBOARDING_PRODUCTS_AVAILABILITY_MAPPER.ENABLED);
        }));
});
