var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { api, invalidateOn, requester } from 'api/shared';
import { DEFAULT_LEGAL_DOCS_LANGUAGE, GET_USER, SERVER_ERRORS } from 'constants/index';
import { LOCALES } from 'i18n/locales';
import { userServerFailure } from 'store/reducers/authSlice';
import { getLanguage } from 'utils/common';
import { USER_MAPPER, OVERALL_STATUS, USER_TENANT_MAPPER, AUTH_CHANNEL_MAPPER, } from 'utils/mappers';
import { notificationErrorHandler } from 'utils/notificationHandlers';
export const initialUserState = {
    id: '',
    firstName: '',
    lastName: '',
    names: '',
    allRequestsTriggeredInternally: false,
    availableEmails: null,
    availablePhone: null,
    availableProducts: [],
    selectedEmail: undefined,
    language: getLanguage(navigator.language),
    legalLanguage: DEFAULT_LEGAL_DOCS_LANGUAGE,
    type: USER_MAPPER.UNKNOWN,
    tenant: USER_TENANT_MAPPER.UNKNOWN,
    channel: AUTH_CHANNEL_MAPPER.UNKNOWN,
    counterparties: [],
    requests: [],
    manualRequests: [],
    manualFulfillmentRequests: [],
    nonManualFulfillmentRequests: [],
    allRequests: [],
    overallStatus: OVERALL_STATUS.UNKNOWN,
    progress: [],
    notSupported: false,
    allowRegisterInterest: false,
    contactRequestHistories: [],
    selectedRequest: undefined,
    lastRequestSelected: false,
    hasTaxId: false,
    emailVerified: false,
    guiCompleted: false,
};
export const USER_ENDPOINTS = {
    getUser: (id) => `/user/${id}`,
    getUserProgress: (id) => `/user/${id}/progress`,
    getUserOverallStatus: (id) => `/user/${id}/status`,
    resetUser: (id) => `/user/${id}/reset`,
    resetEmail: (id) => `/user/${id}/resetEmail`,
    addContactDetails: (userId) => `/user/${userId}/contactDetails`,
    requestEmailVerificationCode: (userId) => `/user/${userId}/email/verify`,
    verifyEmailVerificationCode: (userId, code) => `/user/${userId}/email/verify/${code}`,
    getUserForms: (userId) => `/user/${userId}/onboarding/forms`,
    saveUserLanguage: (userId, language) => `/user/${userId}/language/${language}`,
    saveUserLegalDocsLanguage: (userId, legalLanguage) => `/user/${userId}/legalLanguage/${legalLanguage}`,
    saveOverallStatus: (userId, overallStatus) => `/user/${userId}/status/${overallStatus}`,
    startNewOnboarding: (userId) => `/user/${userId}/restart`,
    setUserTaxID: (userId) => `/user/${userId}/taxid`,
    registerTouristUserInterest: (userId) => `/user/${userId}/registerInterest`,
    setGuiCompleted: (userId) => `/user/${userId}/guiCompleted`,
};
const userApi = api.injectEndpoints({
    endpoints: (build) => ({
        getUser: build.query({
            query: (id) => requester.get(USER_ENDPOINTS.getUser(id)),
            onQueryStarted(_1, _a) {
                return __awaiter(this, arguments, void 0, function* (_, { dispatch, queryFulfilled, getState }) {
                    try {
                        const { data } = yield queryFulfilled;
                        if (!data.language) {
                            yield userApiEndpoints.saveUserLanguage.initiate({
                                id: data.id,
                                language: getLanguage(navigator.language),
                            })(dispatch, getState, _);
                        }
                        if (!data.legalLanguage) {
                            yield userApiEndpoints.saveUserLegalDocsLanguage.initiate({
                                id: data.id,
                                legalLanguage: LOCALES.GERMAN,
                            })(dispatch, getState, _);
                        }
                    }
                    catch ({ error }) {
                        if (SERVER_ERRORS.includes(error === null || error === void 0 ? void 0 : error.status)) {
                            dispatch(userServerFailure());
                        }
                    }
                });
            },
            providesTags: [GET_USER],
        }),
        getUserProgress: build.query({
            query: (id) => requester.get(USER_ENDPOINTS.getUserProgress(id)),
            onQueryStarted(id_1, _a) {
                return __awaiter(this, arguments, void 0, function* (id, { queryFulfilled, dispatch }) {
                    try {
                        const { data } = yield queryFulfilled;
                        if (data) {
                            dispatch(userApi.util.updateQueryData('getUser', id, (draft) => (Object.assign(Object.assign({}, draft), data))));
                        }
                    }
                    catch ({ error }) {
                        notificationErrorHandler(error);
                    }
                });
            },
        }),
        getUserOverallStatus: build.query({
            query: (id) => requester.get(USER_ENDPOINTS.getUserOverallStatus(id)),
            onQueryStarted(id_1, _a) {
                return __awaiter(this, arguments, void 0, function* (id, { queryFulfilled, dispatch }) {
                    try {
                        const { data: newOverallStatus } = yield queryFulfilled;
                        dispatch(userApi.util.updateQueryData('getUser', id, (draft) => (Object.assign(Object.assign({}, draft), { overallStatus: newOverallStatus }))));
                    }
                    catch ({ error }) {
                        notificationErrorHandler(error);
                    }
                });
            },
        }),
        resetUser: build.mutation({
            query: (id) => requester.post(USER_ENDPOINTS.resetUser(id)),
            onQueryStarted(id_1, _a) {
                return __awaiter(this, arguments, void 0, function* (id, { queryFulfilled, dispatch }) {
                    try {
                        const { data } = yield queryFulfilled;
                        if (data) {
                            dispatch(userApi.util.updateQueryData('getUser', id, () => data));
                        }
                    }
                    catch ({ error }) {
                        console.error(error);
                    }
                });
            },
            invalidatesTags: invalidateOn({ success: [GET_USER] }),
        }),
        resetEmail: build.mutation({
            query: (id) => requester.patch(USER_ENDPOINTS.resetEmail(id)),
            onQueryStarted(id_1, _a) {
                return __awaiter(this, arguments, void 0, function* (id, { queryFulfilled, dispatch }) {
                    try {
                        const { data } = yield queryFulfilled;
                        if (data) {
                            dispatch(userApi.util.updateQueryData('getUser', id, () => data));
                        }
                    }
                    catch ({ error }) {
                        console.error(error);
                    }
                });
            },
            invalidatesTags: invalidateOn({ success: [GET_USER] }),
        }),
        addContactDetails: build.mutation({
            query: (_a) => {
                var { userId } = _a, contactDetails = __rest(_a, ["userId"]);
                return requester.patch(USER_ENDPOINTS.addContactDetails(userId), contactDetails);
            },
            onQueryStarted(_a, _b) {
                return __awaiter(this, void 0, void 0, function* () {
                    var { userId } = _a, contactDetails = __rest(_a, ["userId"]);
                    var queryFulfilled = _b.queryFulfilled, dispatch = _b.dispatch;
                    try {
                        const { data: isNewEmailVerified } = yield queryFulfilled;
                        dispatch(userApi.util.updateQueryData('getUser', userId, (draft) => (Object.assign(Object.assign({}, draft), { selectedEmail: contactDetails.email, selectedTelephone: contactDetails.phone, emailVerified: isNewEmailVerified }))));
                    }
                    catch ({ error }) {
                        console.log(error);
                    }
                });
            },
            invalidatesTags: invalidateOn({ success: [GET_USER] }),
        }),
        requestEmailVerificationCode: build.mutation({
            query: (userId) => requester.patch(USER_ENDPOINTS.requestEmailVerificationCode(userId)),
            invalidatesTags: invalidateOn({ success: [GET_USER] }),
        }),
        verifyEmailVerificationCode: build.mutation({
            query: ({ userId, code }) => requester.put(USER_ENDPOINTS.verifyEmailVerificationCode(userId, code)),
            onQueryStarted(_a, _b) {
                return __awaiter(this, arguments, void 0, function* ({ userId }, { dispatch, queryFulfilled }) {
                    try {
                        const { data: isVerifiedSuccessfully } = yield queryFulfilled;
                        dispatch(userApi.util.updateQueryData('getUser', userId, (draft) => (Object.assign(Object.assign({}, draft), { emailVerified: isVerifiedSuccessfully }))));
                    }
                    catch ({ error }) {
                        console.log(error);
                    }
                });
            },
            invalidatesTags: invalidateOn({ success: [GET_USER] }),
        }),
        saveUserLanguage: build.mutation({
            query: ({ id, language }) => requester.patch(USER_ENDPOINTS.saveUserLanguage(id, language)),
            onQueryStarted(_a, _b) {
                return __awaiter(this, arguments, void 0, function* ({ id, language }, { dispatch, getState }) {
                    const { decodedAuthTokenNameId = '' } = getState().auth;
                    const userId = id || decodedAuthTokenNameId;
                    if (userId) {
                        dispatch(userApi.util.updateQueryData('getUser', userId, (draft) => (Object.assign(Object.assign({}, draft), { language }))));
                    }
                    else {
                        dispatch(userApi.util.upsertQueryData('getUser', userId, Object.assign(Object.assign({}, initialUserState), { language })));
                    }
                    // omit usual queryFulfilled.catch(patchResult.undo); to avoid issues when toggling on login portals page
                });
            },
        }),
        saveUserLegalDocsLanguage: build.mutation({
            query: ({ id, legalLanguage }) => requester.patch(USER_ENDPOINTS.saveUserLegalDocsLanguage(id, legalLanguage)),
            onQueryStarted(_a, _b) {
                return __awaiter(this, arguments, void 0, function* ({ id, legalLanguage }, { queryFulfilled, dispatch }) {
                    const patchResult = dispatch(userApi.util.updateQueryData('getUser', id, (draft) => (Object.assign(Object.assign({}, draft), { legalLanguage }))));
                    queryFulfilled.catch(patchResult.undo);
                });
            },
        }),
        saveOverallStatus: build.mutation({
            query: ({ id, overallStatus }) => requester.patch(USER_ENDPOINTS.saveOverallStatus(id, overallStatus)),
            onQueryStarted(_a, _b) {
                return __awaiter(this, arguments, void 0, function* ({ id }, { queryFulfilled, dispatch }) {
                    try {
                        const { data } = yield queryFulfilled;
                        if (data) {
                            dispatch(userApi.util.updateQueryData('getUser', id, () => data));
                        }
                    }
                    catch ({ error }) {
                        console.log(error);
                    }
                });
            },
        }),
        startNewOnboarding: build.mutation({
            query: (userId) => requester.post(USER_ENDPOINTS.startNewOnboarding(userId)),
            onQueryStarted(userId_1, _a) {
                return __awaiter(this, arguments, void 0, function* (userId, { queryFulfilled, dispatch }) {
                    try {
                        const { data } = yield queryFulfilled;
                        if (data) {
                            dispatch(userApi.util.updateQueryData('getUser', userId, () => data));
                        }
                    }
                    catch ({ error }) {
                        console.log(error);
                    }
                });
            },
            invalidatesTags: invalidateOn({
                success: [GET_USER],
            }),
        }),
        setUserTaxID: build.mutation({
            query: ({ id: userId, taxId }) => requester.patch(USER_ENDPOINTS.setUserTaxID(userId), { taxId }),
            onQueryStarted(_a, _b) {
                return __awaiter(this, arguments, void 0, function* ({ id: userId }, { queryFulfilled, dispatch }) {
                    try {
                        const { data } = yield queryFulfilled;
                        if (data) {
                            dispatch(userApi.util.updateQueryData('getUser', userId, () => data));
                        }
                    }
                    catch ({ error }) {
                        console.log(error);
                    }
                });
            },
        }),
        registerTouristUserInterest: build.mutation({
            query: (_a) => {
                var { id: userId } = _a, payload = __rest(_a, ["id"]);
                return requester.post(USER_ENDPOINTS.registerTouristUserInterest(userId), payload);
            },
            onQueryStarted(_a, _b) {
                return __awaiter(this, arguments, void 0, function* ({ id: userId }, { queryFulfilled, dispatch }) {
                    try {
                        const { data } = yield queryFulfilled;
                        if (data) {
                            dispatch(userApi.util.updateQueryData('getUser', userId, () => data));
                        }
                    }
                    catch ({ error }) {
                        console.log(error);
                    }
                });
            },
        }),
        setGuiCompleted: build.mutation({
            query: ({ id: userId, guiCompleted }) => requester.patch(USER_ENDPOINTS.setGuiCompleted(userId), { guiCompleted }),
            onQueryStarted(_a, _b) {
                return __awaiter(this, arguments, void 0, function* ({ id: userId }, { queryFulfilled, dispatch }) {
                    try {
                        const { data } = yield queryFulfilled;
                        if (data) {
                            dispatch(userApi.util.updateQueryData('getUser', userId, () => data));
                        }
                    }
                    catch ({ error }) {
                        console.log(error);
                    }
                });
            },
        }),
    }),
    overrideExisting: false,
});
export const userApiEndpoints = userApi.endpoints;
export const userApiUtils = userApi.util;
export const { useGetUserQuery, useLazyGetUserQuery, useGetUserProgressQuery, useLazyGetUserProgressQuery, useGetUserOverallStatusQuery, useLazyGetUserOverallStatusQuery, useResetUserMutation, useResetEmailMutation, useAddContactDetailsMutation, useRequestEmailVerificationCodeMutation, useVerifyEmailVerificationCodeMutation, useSaveUserLanguageMutation, useSaveUserLegalDocsLanguageMutation, useSaveOverallStatusMutation, useStartNewOnboardingMutation, useSetUserTaxIDMutation, useRegisterTouristUserInterestMutation, useSetGuiCompletedMutation, } = userApi;
