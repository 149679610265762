import React from 'react';
import { useSelector } from 'react-redux';
import { Route, Routes, useLocation } from 'react-router-dom';
import { Loader, ProtectedRoute } from 'components';
import { ROUTES, TOURIST_USERS_RELATED_ROUTES } from 'constants/index';
import { authSelector, userSelector, browserSelector, authenticatedRoutesSelector, } from 'store';
import { ENVELOPES_VALIDATION_PAGE_ACCESSIBLE_STATUSES, ONBOARDING_PAGE_ACCESSIBLE_STATUSES, OVERALL_STATUS, } from 'utils/mappers';
const LandingPage = React.lazy(() => import('pages/LandingPage'));
const ContactDetails = React.lazy(() => import('pages/ContactDetails'));
const EmailVerification = React.lazy(() => import('pages/EmailVerification'));
const FxOnboardingRequests = React.lazy(() => import('pages/FxOnboardingRequests'));
const ClientLookup = React.lazy(() => import('pages/ClientLookup'));
const FxOnboardingForms = React.lazy(() => import('pages/FxOnboardingForms'));
const NotFound = React.lazy(() => import('pages/NotFound'));
const UnsupportedBrowser = React.lazy(() => import('pages/UnsupportedBrowser'));
const UnsupportedUser = React.lazy(() => import('pages/NotSupported'));
const TouristUsers = React.lazy(() => import('pages/TouristUsers'));
const TouristUsersCompleted = React.lazy(() => import('pages/TouristUsersCompleted'));
const LoginPortals = React.lazy(() => import('pages/LoginPortals'));
const CookiesDisabled = React.lazy(() => import('pages/CookiesDisabled'));
const EnvelopesValidation = React.lazy(() => import('pages/EnvelopesValidation'));
const OnboardingStatus = React.lazy(() => import('pages/OnboardingStatus'));
const ServerFail = React.lazy(() => import('pages/ServerFail'));
const ErrorPage = React.lazy(() => import('pages/ErrorPage'));
const generateRedirectToRoute = (
// @TODO think of appropriate file to put this util into
pathname, sessionValid, user, browser, navigator) => {
    var _a, _b, _c, _d;
    switch (true) {
        case navigator.cookieEnabled === false:
            return ROUTES.COOKIES_DISABLED;
        case sessionValid === false:
            return ROUTES.LOGIN_PORTALS;
        case browser.isSupported === false:
            return ROUTES.UNSUPPORTED_BROWSER;
        case ENVELOPES_VALIDATION_PAGE_ACCESSIBLE_STATUSES.includes(user.overallStatus):
            return ROUTES.ENVELOPES_VALIDATION;
        case ONBOARDING_PAGE_ACCESSIBLE_STATUSES.includes(user.overallStatus):
            return ROUTES.ONBOARDING_STATUS;
        case pathname === ROUTES.ONBOARDING_REQUESTS && ((_a = user === null || user === void 0 ? void 0 : user.requests) === null || _a === void 0 ? void 0 : _a.length) === 0:
            return ROUTES.ACCOUNT_SELECTION;
        case pathname === ROUTES.ONBOARDING_FORMS &&
            ((_b = user === null || user === void 0 ? void 0 : user.requests) === null || _b === void 0 ? void 0 : _b.some((req) => req.isValidated === false)):
            return ROUTES.ONBOARDING_REQUESTS;
        case ((_c = user === null || user === void 0 ? void 0 : user.requests) === null || _c === void 0 ? void 0 : _c.length) && ((_d = user === null || user === void 0 ? void 0 : user.requests) === null || _d === void 0 ? void 0 : _d.every((req) => req.triggeredInternally)):
            return ROUTES.ONBOARDING_FORMS;
        case TOURIST_USERS_RELATED_ROUTES.includes(pathname) &&
            (!user.selectedEmail || !user.selectedTelephone):
            return ROUTES.CONTACT_DETAILS;
        case pathname === ROUTES.TOURIST_COMPLETED &&
            user.overallStatus !== OVERALL_STATUS.COMPLETED_TOURIST:
            return ROUTES.LANDING_PAGE;
        case pathname !== ROUTES.TOURIST_COMPLETED &&
            user.overallStatus === OVERALL_STATUS.COMPLETED_TOURIST:
            return ROUTES.TOURIST_COMPLETED;
        case pathname !== ROUTES.UNSUPPORTED_USER && !user.allowRegisterInterest:
            return ROUTES.UNSUPPORTED_USER;
        case pathname !== ROUTES.EMAIL_VERIFICATION && user.selectedEmail && !user.emailVerified:
            return ROUTES.EMAIL_VERIFICATION;
        default:
            return browser.redirectTo || ROUTES.LANDING_PAGE;
    }
};
export const RouteSwitch = () => {
    const location = useLocation();
    const user = useSelector(userSelector);
    const browser = useSelector(browserSelector);
    const { sessionValid } = useSelector(authSelector);
    const canActivate = useSelector(authenticatedRoutesSelector);
    const redirectTo = generateRedirectToRoute(location.pathname, sessionValid, user, browser, navigator);
    return (React.createElement(Routes, { key: location.pathname, location: location },
        React.createElement(Route, { path: ROUTES.LOGIN_PORTALS, element: React.createElement(React.Suspense, { fallback: React.createElement(Loader, null) },
                React.createElement(ProtectedRoute, { canActivate: canActivate.LOGIN_PORTALS, redirectTo: redirectTo },
                    React.createElement(LoginPortals, null))) }),
        React.createElement(Route, { path: ROUTES.LANDING_PAGE, element: React.createElement(React.Suspense, { fallback: React.createElement(Loader, null) },
                React.createElement(ProtectedRoute, { canActivate: canActivate.LANDING_PAGE, redirectTo: redirectTo },
                    React.createElement(LandingPage, null))) }),
        React.createElement(Route, { path: ROUTES.CONTACT_DETAILS, element: React.createElement(React.Suspense, { fallback: React.createElement(Loader, null) },
                React.createElement(ProtectedRoute, { canActivate: canActivate.CONTACT_DETAILS, redirectTo: redirectTo },
                    React.createElement(ContactDetails, null))) }),
        React.createElement(Route, { path: ROUTES.EMAIL_VERIFICATION, element: React.createElement(React.Suspense, { fallback: React.createElement(Loader, null) },
                React.createElement(ProtectedRoute, { canActivate: canActivate.EMAIL_VERIFICATION, redirectTo: redirectTo },
                    React.createElement(EmailVerification, null))) }),
        React.createElement(Route, { path: ROUTES.ACCOUNT_SELECTION, element: React.createElement(React.Suspense, { fallback: React.createElement(Loader, null) },
                React.createElement(ProtectedRoute, { canActivate: canActivate.ACCOUNT_SELECTION, redirectTo: redirectTo },
                    React.createElement(ClientLookup, null))) }),
        React.createElement(Route, { path: ROUTES.ONBOARDING_REQUESTS, element: React.createElement(React.Suspense, { fallback: React.createElement(Loader, null) },
                React.createElement(ProtectedRoute, { canActivate: canActivate.ONBOARDING_REQUESTS, redirectTo: redirectTo },
                    React.createElement(FxOnboardingRequests, null))) }),
        React.createElement(Route, { path: ROUTES.ONBOARDING_FORMS, element: React.createElement(React.Suspense, { fallback: React.createElement(Loader, null) },
                React.createElement(ProtectedRoute, { canActivate: canActivate.ONBOARDING_FORMS, redirectTo: redirectTo },
                    React.createElement(FxOnboardingForms, null))) }),
        React.createElement(Route, { path: ROUTES.ENVELOPES_VALIDATION, element: React.createElement(React.Suspense, { fallback: React.createElement(Loader, null) },
                React.createElement(ProtectedRoute, { canActivate: canActivate.ENVELOPES_VALIDATION, redirectTo: redirectTo },
                    React.createElement(EnvelopesValidation, null))) }),
        React.createElement(Route, { path: ROUTES.ONBOARDING_STATUS, element: React.createElement(React.Suspense, { fallback: React.createElement(Loader, null) },
                React.createElement(ProtectedRoute, { canActivate: canActivate.ONBOARDING_STATUS, redirectTo: redirectTo },
                    React.createElement(OnboardingStatus, null))) }),
        React.createElement(Route, { path: ROUTES.UNSUPPORTED_BROWSER, element: React.createElement(React.Suspense, { fallback: React.createElement(Loader, null) },
                React.createElement(ProtectedRoute, { canActivate: canActivate.UNSUPPORTED_BROWSER, redirectTo: redirectTo },
                    React.createElement(UnsupportedBrowser, null))) }),
        React.createElement(Route, { path: ROUTES.UNSUPPORTED_USER, element: React.createElement(React.Suspense, { fallback: React.createElement(Loader, null) },
                React.createElement(ProtectedRoute, { canActivate: canActivate.UNSUPPORTED_USER, redirectTo: redirectTo },
                    React.createElement(UnsupportedUser, null))) }),
        React.createElement(Route, { path: ROUTES.TOURIST_USERS, element: React.createElement(React.Suspense, { fallback: React.createElement(Loader, null) },
                React.createElement(ProtectedRoute, { canActivate: canActivate.TOURIST_USERS, redirectTo: redirectTo },
                    React.createElement(TouristUsers, null))) }),
        React.createElement(Route, { path: ROUTES.TOURIST_COMPLETED, element: React.createElement(React.Suspense, { fallback: React.createElement(Loader, null) },
                React.createElement(ProtectedRoute, { canActivate: canActivate.TOURIST_COMPLETED, redirectTo: redirectTo },
                    React.createElement(TouristUsersCompleted, null))) }),
        React.createElement(Route, { path: ROUTES.COOKIES_DISABLED, element: React.createElement(React.Suspense, { fallback: React.createElement(Loader, null) },
                React.createElement(ProtectedRoute, { canActivate: canActivate.COOKIES_DISABLED, redirectTo: redirectTo },
                    React.createElement(CookiesDisabled, null))) }),
        React.createElement(Route, { path: ROUTES.LOGIN_FAILED, element: React.createElement(React.Suspense, { fallback: React.createElement(Loader, null) },
                React.createElement(ProtectedRoute, { canActivate: canActivate.LOGIN_FAILED, redirectTo: redirectTo },
                    React.createElement(ServerFail, { variant: "login" }))) }),
        React.createElement(Route, { path: ROUTES.USER_FAILED, element: React.createElement(React.Suspense, { fallback: React.createElement(Loader, null) },
                React.createElement(ProtectedRoute, { canActivate: canActivate.USER_FAILED, redirectTo: redirectTo },
                    React.createElement(ServerFail, { variant: "user" }))) }),
        React.createElement(Route, { path: ROUTES.ERROR_PAGE, element: React.createElement(React.Suspense, { fallback: React.createElement(Loader, null) },
                React.createElement(ProtectedRoute, { canActivate: canActivate.ERROR_PAGE, redirectTo: redirectTo },
                    React.createElement(ErrorPage, null))) }),
        React.createElement(Route, { path: "*", element: React.createElement(React.Suspense, { fallback: React.createElement(Loader, null) },
                React.createElement(NotFound, null)) })));
};
export default RouteSwitch;
